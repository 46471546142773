import React, { useEffect } from 'react'
import "./SuccessPage.css"
import { Link, useLocation } from 'react-router-dom'
const SuccessPage = () => {
    const location = useLocation();
    const orderId = new URLSearchParams(location.search).get('orderId');

    // useEffect(() => {
    //     // You can now use the orderId in your component
    //     console.log('Order ID:', orderId);
    //   }, [orderId]);
    return (
        <div class="container mt-5">
            <div class="row">
                <div class="col-md-6 offset-md-3 text-center">
                    {/* <img src="path/to/success-icon.png" alt="Success Icon" class="img-fluid success-icon" /> */}
                    <div class="success-animation">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                        <h3 className='mt-3'>Payment successfull .</h3>
                        <div className='mt-3'>
                            <h2>Order Placed Successfully! </h2>
                            <p>Your order has been confirmed. Thank you for shopping with us.</p>
                            <p>Order Id  :  {orderId} </p>
                            <Link to="/home" class="btn footerbg p-2 rounded text-white"><i class="fas fa-home fa-lg"></i>  Back to Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessPage