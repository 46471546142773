import React, { useContext, useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import AddAddressModal from '../AddAddressModal';
import ShoppingCart from '../ShoppingCart';
import { UserContext } from '../Api/context/AppContext';



const OrderForm = ({ totalCartPrice, errors, handleInputChange, formData, usedetails }) => {
  const [selectedBillingAddress, setSelectedBillingAddress] = useState('');
  const [showModal, setShowModal] = useState(false);
  // console.log(formData, "cvbnmlkjhgfdfghj")
  
  const handleBillingAddressChange = (fieldName, value) => {
    handleInputChange(fieldName, value);
    setSelectedBillingAddress(value);
  };

  const handleCloseModal = () => {
    setShowModal(false);

  };

  
  const selectedBillingDetails = usedetails.find(item => item.id === selectedBillingAddress);
  const displayGstNumber = selectedBillingDetails ? selectedBillingDetails.gst_no : '';
  
  


  return (
    <>
   
      <div className="mb-4">
        <h4 className="font-weight-semi-bold mb-4">Order Form<i class="fas fa-shopping-bag text-warning"></i></h4>
        {/* Your form fields */}
        <div className="row">
          <div className="col-md-6 form-group">
            <label>Billing Company Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="John"
              onChange={(e) => handleInputChange('billing_company_name', e.target.value)}
              value={formData.billing_company_name}
            />
            {/* <small className="text-danger">{errors.billing_company_name}</small> */}
          </div>
          <div className="col-md-6 form-group d-none ">
            <label>Billing Pincode</label>
            <input
              className="form-control"
              type="text"
              placeholder="Pincode"
              value={formData.billing_pincode}
              onChange={(e) => handleInputChange('billing_pincode', e.target.value)}
            />
            {/* <small className="text-danger">{errors.billing_company_name}</small> */}
          </div>
          <div className="col-md-6 form-group d-none">
            <label>Billing GST No</label>
            <input
              className="form-control"
              type="text"
              placeholder="GST No"
              // value={formData.billing_gst_no}
              value={displayGstNumber}
              // value={displayGstNumber}
              onChange={(e) => handleInputChange('billing_gst_no', e.target.value)}
            />

            <small className="text-danger">{errors.billing_gst_no}</small>
          </div>

          <div className="col-md-6 form-group d-none">
            <label>Shipping Company Name</label>
            <input
              className="form-control"
              type="text"
              value={formData.shipping_company_name}
              placeholder="Shipping Company"
              onChange={(e) => handleInputChange('shipping_company_name', e.target.value)}
            />
            <small className="text-danger">{errors.shipping_company_name}</small>
          </div>
          <div className="col-md-6 form-group d-none">
            <label>Shipping Pincode</label>
            <input
              className="form-control"
              type="text"
              placeholder="Shipping Pincode"
              value={formData.shipping_pincode}
              onChange={(e) => handleInputChange('shipping_pincode', e.target.value)}
            />
            <small className="text-danger">{errors.shipping_pincode}</small>
          </div>
          <div className="col-md-6 form-group">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
            />
            <small className="text-danger">{errors.name}</small>
          </div>
          <div className="col-md-6 form-group">
            <label>Mobile No</label>
            <input
              className="form-control"
              type="text"
              placeholder="Mobile No"
              value={formData.mobile}
              onChange={(e) => handleInputChange('mobile', e.target.value)}
            />
            <small className="text-danger">{errors.mobile}</small>
          </div>
          <div className="col-md-6 form-group">
            <label>E-mail</label>
            <input
              className="form-control"
              type="text"
              placeholder="example@email.com"
              value={formData.email}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
            <small className="text-danger">{errors.email}</small>
          </div>
        </div>

        <div className='d-flex'>
          {/* <div className="mb-3 mr-2 w-50">
          <label htmlFor="market_place" className="register-label">Billing Address:</label>
          <select
            id="market_place"
            name="market_place"
            value={formData.billing_address} // Set the value attribute to manage the selected value
            onChange={(e) => handleInputChange('billing_address', e.target.value)}
            className="form-select p-2"
          >
            <option value="">Select a billing address</option>
            {usedetails.map((item) => (
              <option key={item.id} value={item.id}>
                {item.address}
              </option>
            ))}
          </select>

          
        </div> */}

          <div className="mb-3 mr-2 w-50">
            <label htmlFor="billing_address" className="register-label">Billing Address:</label>
            <select
              id="billing_address"
              name="billing_address"
              value={formData.billing_address}
              onChange={(e) => handleBillingAddressChange('billing_address', e.target.value)}
              className="form-select p-2"
            >
              <option value="">Select a billing address</option>
              {usedetails.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.address}
                </option>
              ))}
            </select>
          </div>



          <div className="mb-3 w-50 ml-2">
            <label htmlFor="market_place" className="register-label">shipping Address:</label>
            <select
              id="market_place"
              name="market_place"
              // Set the value attribute to manage the selected value
              onChange={(e) => handleInputChange('shipping_address', e.target.value)}
              className="form-select p-2"
            >
              <option value={formData.shipping_address}>Select a shipping address</option>
              {usedetails.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.address}
                </option>
              ))}
            </select>

            {/* {error && error.includes('Market') && <p className="text-danger">{error.split('Market: ')[1].split(', Org:')[0]}</p>} */}
          </div>


        </div>
        <div className='row'>
          <div className='col-md-6 w-50'>
            <label>GST No:</label>
            <input
              className="form-control"
              type="text"
              placeholder="GST No"
              value={displayGstNumber}
              disabled
            />
          </div>
          <div className='col-md-6 w-50 d-flex justify-content-center align-items-end'>
            <label>Not your address ?</label>
            <button onClick={() => setShowModal(true)} className='h-50 footerbg text-white rounded'>Add New Address</button>
            <AddAddressModal show={showModal} handleClose={handleCloseModal} formData={formData} />
          </div>
        </div>
      </div>
    </>


  );
};

export default OrderForm;


// import React, { useState } from 'react';
// import axios from 'axios';

// const OrderForm = ({ totalCartPrice, errors, handleInputChange, formData, usedetails }) => {

//   // console.log(errors.billing_gst_no,"_______errors.billing_gst_no")
//   console.log(formData,"bhushandatat")
//   return (
//     <div className="mb-4">
//       <h4 className="font-weight-semi-bold mb-4">Order F <i class="fas fa-shopping-bag text-warning"></i></h4> 
//       {/* Your form fields */}
//       <div className="row">
//         <div className="col-md-6 form-group">
//           <label>Billing Company Name</label>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="John"
//             onChange={(e) => handleInputChange('billing_company_name', e.target.value)}
//             value={formData.billing_company_name}
//           />
//           {/* <small className="text-danger">{errors.billing_company_name}</small> */}
//         </div>
//         <div className="col-md-6 form-group d-none ">
//           <label>Billing Pincode</label>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Pincode"
//             value={formData.billing_pincode}
//             onChange={(e) => handleInputChange('billing_pincode', e.target.value)}
//           />
//           {/* <small className="text-danger">{errors.billing_company_name}</small> */}
//         </div>
//         <div className="col-md-6 form-group d-none">
//           <label>Billing GST No</label>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="GST No"
//             value={formData.billing_gst_no}
//             onChange={(e) => handleInputChange('billing_gst_no', e.target.value)}
//           />
//           <small className="text-danger">{errors.billing_gst_no}</small>
//         </div>
//         <div className="col-md-6 form-group d-none">
//           <label>Shipping Company Name</label>
//           <input
//             className="form-control"
//             type="text"
//             value={formData.shipping_company_name}
//             placeholder="Shipping Company"
//             onChange={(e) => handleInputChange('shipping_company_name', e.target.value)}
//           />
//           <small className="text-danger">{errors.shipping_company_name}</small>
//         </div>
//         <div className="col-md-6 form-group d-none">
//           <label>Shipping Pincode</label>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Shipping Pincode"
//             value={formData.shipping_pincode}
//             onChange={(e) => handleInputChange('shipping_pincode', e.target.value)}
//           />
//           <small className="text-danger">{errors.shipping_pincode}</small>
//         </div>
//         <div className="col-md-6 form-group">
//           <label>Name</label>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Name"
//             value={formData.name}
//             onChange={(e) => handleInputChange('name', e.target.value)}
//           />
//           <small className="text-danger">{errors.name}</small>
//         </div>
//         <div className="col-md-6 form-group">
//           <label>Mobile No</label>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="Mobile No"
//             value={formData.mobile}
//             onChange={(e) => handleInputChange('mobile', e.target.value)}
//           />
//           <small className="text-danger">{errors.mobile}</small>
//         </div>
//         <div className="col-md-6 form-group">
//           <label>E-mail</label>
//           <input
//             className="form-control"
//             type="text"
//             placeholder="example@email.com"
//             value={formData.email}
//             onChange={(e) => handleInputChange('email', e.target.value)}
//           />
//           <small className="text-danger">{errors.email}</small>
//         </div>
//       </div>

//       <div className='d-flex'>
//         <div className="mb-3 mr-2 w-50">
//           <label htmlFor="market_place" className="register-label">Billing Address:</label>
//           <select
//             id="market_place"
//             name="market_place"
//             value={formData.billing_address} // Set the value attribute to manage the selected value
//             onChange={(e) => handleInputChange('billing_address', e.target.value)}
//             className="form-select p-2"
//           >
//             <option value="">Select a market place</option>
//             {usedetails.map((item) => (
//               <option key={item.id} value={item.id}>
//                 {item.address}
//               </option>
//             ))}
//           </select>

//           {/* {error && error.includes('Market') && <p className="text-danger">{error.split('Market: ')[1].split(', Org:')[0]}</p>} */}
//         </div>

//         <div className="mb-3 w-50 ml-2">
//           <label htmlFor="market_place" className="register-label">shipping Address:</label>
//           <select
//             id="market_place"
//             name="market_place"
//             // Set the value attribute to manage the selected value
//             onChange={(e) => handleInputChange('shipping_address', e.target.value)}
//             className="form-select p-2"
//           >
//             <option value={formData.shipping_address}>Select a market place</option>
//             {usedetails.map((item) => (
//               <option key={item.id} value={item.id}>
//                 {item.address}
//               </option>
//             ))}
//           </select>

//           {/* {error && error.includes('Market') && <p className="text-danger">{error.split('Market: ')[1].split(', Org:')[0]}</p>} */}
//         </div>
//       </div>



//     </div>
//   );
// };

// export default OrderForm;






// import React, { useContext, useState } from 'react';
// import axios from 'axios';
// import { Modal, Button } from 'react-bootstrap';
// import AddAddressModal from '../AddAddressModal';
// import ShoppingCart from '../ShoppingCart';
// import { UserContext } from '../Api/context/AppContext';



// const OrderForm = ({ totalCartPrice, errors, handleInputChange, formData, usedetails }) => {
//   const [selectedBillingAddress, setSelectedBillingAddress] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   console.log(formData, "bhushandetails")
  
//   const handleBillingAddressChange = (fieldName, value) => {
//     handleInputChange(fieldName, value);
//     setSelectedBillingAddress(value);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);

//   };

//   const selectedBillingDetails = usedetails.find(item => item.id === selectedBillingAddress);
//   const displayGstNumber = selectedBillingDetails ? selectedBillingDetails.gst_no : '';

  


//   return (
//     <>
   
//       <div className="mb-4">
//         <h4 className="font-weight-semi-bold mb-4">Order Form<i class="fas fa-shopping-bag text-warning"></i></h4>
//         {/* Your form fields */}
//         <div className="row">
//           <div className="col-md-6 form-group">
//             <label>Billing Company Name</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="John"
//               onChange={(e) => handleInputChange('billing_company_name', e.target.value)}
//               value={formData.billing_company_name}
//             />
//             {/* <small className="text-danger">{errors.billing_company_name}</small> */}
//           </div>
//           <div className="col-md-6 form-group d-none ">
//             <label>Billing Pincode</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="Pincode"
//               value={formData.billing_pincode}
//               onChange={(e) => handleInputChange('billing_pincode', e.target.value)}
//             />
//             {/* <small className="text-danger">{errors.billing_company_name}</small> */}
//           </div>
//           <div className="col-md-6 form-group d-none">
//             <label>Billing GST No</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="GST No"
//               value={formData.billing_gst_no}
//               // value={displayGstNumber}
//               onChange={(e) => handleInputChange('billing_gst_no', e.target.value)}
//             />

//             <small className="text-danger">{errors.billing_gst_no}</small>
//           </div>

//           <div className="col-md-6 form-group d-none">
//             <label>Shipping Company Name</label>
//             <input
//               className="form-control"
//               type="text"
//               value={formData.shipping_company_name}
//               placeholder="Shipping Company"
//               onChange={(e) => handleInputChange('shipping_company_name', e.target.value)}
//             />
//             <small className="text-danger">{errors.shipping_company_name}</small>
//           </div>
//           <div className="col-md-6 form-group d-none">
//             <label>Shipping Pincode</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="Shipping Pincode"
//               value={formData.shipping_pincode}
//               onChange={(e) => handleInputChange('shipping_pincode', e.target.value)}
//             />
//             <small className="text-danger">{errors.shipping_pincode}</small>
//           </div>
//           <div className="col-md-6 form-group">
//             <label>Name</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="Name"
//               value={formData.name}
//               onChange={(e) => handleInputChange('name', e.target.value)}
//             />
//             <small className="text-danger">{errors.name}</small>
//           </div>
//           <div className="col-md-6 form-group">
//             <label>Mobile No</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="Mobile No"
//               value={formData.mobile}
//               onChange={(e) => handleInputChange('mobile', e.target.value)}
//             />
//             <small className="text-danger">{errors.mobile}</small>
//           </div>
//           <div className="col-md-6 form-group">
//             <label>E-mail</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="example@email.com"
//               value={formData.email}
//               onChange={(e) => handleInputChange('email', e.target.value)}
//             />
//             <small className="text-danger">{errors.email}</small>
//           </div>
//         </div>

//         <div className='d-flex'>
//           {/* <div className="mb-3 mr-2 w-50">
//           <label htmlFor="market_place" className="register-label">Billing Address:</label>
//           <select
//             id="market_place"
//             name="market_place"
//             value={formData.billing_address} // Set the value attribute to manage the selected value
//             onChange={(e) => handleInputChange('billing_address', e.target.value)}
//             className="form-select p-2"
//           >
//             <option value="">Select a billing address</option>
//             {usedetails.map((item) => (
//               <option key={item.id} value={item.id}>
//                 {item.address}
//               </option>
//             ))}
//           </select>

          
//         </div> */}

//           <div className="mb-3 mr-2 w-50">
//             <label htmlFor="billing_address" className="register-label">Billing Address:</label>
//             <select
//               id="billing_address"
//               name="billing_address"
//               value={formData.billing_address}
//               onChange={(e) => handleBillingAddressChange('billing_address', e.target.value)}
//               className="form-select p-2"
//             >
//               <option value="">Select a billing address</option>
//               {usedetails.map((item) => (
//                 <option key={item.id} value={item.id}>
//                   {item.address}
//                 </option>
//               ))}
//             </select>
//           </div>



//           <div className="mb-3 w-50 ml-2">
//             <label htmlFor="market_place" className="register-label">shipping Address:</label>
//             <select
//               id="market_place"
//               name="market_place"
//               // Set the value attribute to manage the selected value
//               onChange={(e) => handleInputChange('shipping_address', e.target.value)}
//               className="form-select p-2"
//             >
//               <option value={formData.shipping_address}>Select a shipping address</option>
//               {usedetails.map((item) => (
//                 <option key={item.id} value={item.id}>
//                   {item.address}
//                 </option>
//               ))}
//             </select>

//             {/* {error && error.includes('Market') && <p className="text-danger">{error.split('Market: ')[1].split(', Org:')[0]}</p>} */}
//           </div>


//         </div>
//         <div className='row'>
//           <div className='col-md-6 w-50'>
//             <label>GST No:</label>
//             <input
//               className="form-control"
//               type="text"
//               placeholder="GST No"
//               value={displayGstNumber}
//               disabled
//             />
//           </div>
//           <div className='col-md-6 w-50 d-flex justify-content-center align-items-end'>
//             <label>Not your address ?</label>
//             <button onClick={() => setShowModal(true)} className='h-50 footerbg text-white rounded'>Add New Address</button>
//             {/* <AddAddressModal show={showModal} handleClose={handleCloseModal} formData={formData}/> */}
//           </div>
//         </div>
//       </div>
//     </>


//   );
// };

// export default OrderForm;




