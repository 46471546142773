import React from 'react'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { UserContext } from '../Api/context/AppContext'

const ProductCard = ({ item }) => {
    const { user } = useContext(UserContext);
    const [quantity, setQuantity] = useState(1);


    const incQty = () => {
        setQuantity((prevQty) => prevQty + 1);
    };

    const decQty = () => {
        setQuantity((prevQty) => {
            if (prevQty > 1) {
                return prevQty - 1;
            }
            return prevQty; // Quantity shouldn't go below 1
        });
    }



    //    const itemImage = item.documents.map((itemurl)=>{
    //         console.log(itemurl,"_____image")
    //    }) 


    // const attachment = itemImage && itemImage.length > 0 ? itemImage[0].attachment : null;

    // const itemPrice = item.part_no;


    const itemImage = item.part_no.default;
    const attachment = itemImage && itemImage.attachment ? itemImage.attachment : null;

    const itemImage1 = item.part_no.documents;
    const attachment1 = itemImage1 && itemImage1.length > 0 ? itemImage1[0].attachment.attachment : null;

    // console.log(attachment1, "_______--attachment1");

    // ...

    const finalImage = attachment1 || attachment;

    // console.log(finalImage, "_______--finalImage");

    const { addToCard, checkId } = useContext(UserContext)


    // checkId?.map((ckeid)=>{
    //      console.log(ckeid,"__________ckeid")
    // })   

    return (
        <>
            <div className=" col-md-4 pb-1">
                <div className="card product-item  mb-4">
                    <div className="card-header product-img position-relative overflow-hidden bg-transparent  p-3">
                        <img
                            className="img-fluid2 w-100 img-wrap"
                            src={finalImage}
                            alt="please add a image "
                        />
                    </div>

                    {/* <Link to={`/home/singleproduct/${item.part_no.part_number}`} className="card-body text-center p-0 pt-4 pb-3">
                        <div className="text-truncate fixed-height-div  mb-3 p-3 text-dark">
                            {item.part_no.short_description}
                        </div>

                        <div className="d-flex justify-content-center">
                            <h6>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.part_no.mrp)}</h6>

                        </div>
                    </Link> */}





                    <Link to={`/home/singleproduct/${item.part_no.part_number}`} className="card-body text-center p-0 pt-4 pb-3">
                        <div className="text-truncate fixed-height-div  mb-3 p-3 text-dark">
                            {item.part_no.short_description}
                        </div>

                        <div className="d-flex justify-content-center">
                            <h6>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(item.part_no.mrp)}</h6>

                        </div>
                    </Link>





                    {/* <div className=" d-flex justify-content-between bg-light ">




                        <button type="button" className='btn footerbg  mb-2 rounded buttoneye'>
                            <Link
                                to={`/home/singleproduct/${item.part_no.part_number}`} className="btn  text-dark  text-center ">
                                <i className="fas fa-eye text-dark "></i>View
                                Detail
                            </Link>
                        </button>



                        


                        {!checkId.includes(item.part_no.id) ? (
                            <button
                                onClick={() => addToCard(item.part_no, quantity)}
                                type="button" className="btn buttonbg text-white  btn-dark text-center px-2 mb-2 rounded  buttoncart"
                            >
                                <i className="fas fa-shopping-cart text-white "></i> Add To Cart

                            </button>
                        ) : (
                            <Link to="/home/cart"

                                className="btn  text-white  buttonbg btn-dark text-center px-2 mb-2 rounded "
                            >
                                <i className="fas fa-shopping-cart mx-2"></i>
                                Go To Cart
                            </Link>
                        )}




                    </div> */}


                    {user ? (

                        <div className=" d-flex justify-content-between bg-light ">




                            <button type="button" className='btn footerbg  mb-2 rounded buttoneye'>
                                <Link
                                    to={`/home/singleproduct/${item.part_no.part_number}`} className="btn  text-dark  text-center ">
                                    <i className="fas fa-eye text-dark "></i>View
                                    Detail
                                </Link>
                            </button>



                            {/* //original code// */}


                            {!checkId.includes(item.part_no.id) ? (
                                <button
                                    onClick={() => addToCard(item.part_no, quantity)}
                                    type="button" className="btn buttonbg text-white  btn-dark text-center px-2 mb-2 rounded  buttoncart"
                                >
                                    <i className="fas fa-shopping-cart text-white "></i> Add To Cart

                                </button>
                            ) : (
                                <Link to="/home/cart"

                                    className="btn  text-white  buttonbg btn-dark text-center px-2 mb-2 rounded "
                                >
                                    <i className="fas fa-shopping-cart mx-2"></i>
                                    Go To Cart
                                </Link>
                            )}




                        </div>

                    ) : (
                        <div className=" d-flex justify-content-center bg-light ">




                        <button type="button" className='btn footerbg  mb-2 rounded buttoneye'>
                            <Link
                                to={`/home/singleproduct/${item.part_no.part_number}`} className="btn  text-dark  text-center ">
                                <i className="fas fa-eye text-dark "></i>View
                                Detail
                            </Link>
                        </button>


                    </div>


                    )}

                </div>
            </div >



        </>
    )
}

export default ProductCard