import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./Api/context/AppContext";

import logodeafault from "../components/assets/logowikitek.png";
import Categorieslist from "./Categorieslist";


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const { user, handleLogout, cartlen } = useContext(UserContext);
  const [logo, setLogo] = useState(logodeafault);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);


  useEffect(() => {
    const userNameAmit = localStorage.getItem("emailData");
    const UserPassword = localStorage.getItem("PasswordAmit");

    if (user && !isLogoLoaded) {
      axios
        .post("http://143.244.142.0/api/v1/accounts/login", {
          username: userNameAmit,
          password: UserPassword,
        })
        .then((response) => {
          const { data } = response;
          if (data && data.success) {
            const organization = data.data.org;
            if (organization && organization.logo) {
              // setLogo(`http://143.244.142.0${organization.logo}`);
              setIsLogoLoaded(true); // Set the flag to prevent further logo updates
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching login data:", error);
        });
    }
  }, [user, isLogoLoaded]);


  return (
    <>

      <div className="container-fluid d-flex justify-content-between footerbg align-items-center mb-2  bg-light pt-3 pb-3 navtopbar">

        <div className="d-flex  logodiv">
          {/* <Link to="/home" className="text-decoration-none">
            <h2 className="m-0 display-5 font-weight-semi-bold ">

              <img className="logo_head" src={logo} alt="No Img" />
            </h2>
          </Link> */}

          {user ? (

            <Link to="/home" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold ">

                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>


          ) : (

            <Link to="/" className="text-decoration-none">
              <h2 className="m-0 display-5 font-weight-semi-bold ">

                <img className="logo_head" src={logo} alt="No Img" />
              </h2>
            </Link>

          )}
        </div>



        <div className="buttondiv">



          {user ? (

            <Link to="/home/orderhistory" className="btn  text-dark">
              <span className="fs-1"><i class="fas fa-solid fa-user shake-icon"></i> </span>
            </Link>

          ) : (
            <Link ></Link>


          )}

          {user ? (

            <Link to="/home/cart" className="btn   text-dark ">
              <i className="fas fa-shopping-cart shake-icon"></i>
              {!!cartlen && <span className=""> {cartlen}</span>}
            </Link>

          ) : (
            <Link ></Link>


          )}


          {user ? (

            <span
              onClick={handleLogout} >
              <Link to="/login" className="text-dark"><i class="fas fa-sign-out-alt"></i></Link>
            </span>

          ) : (
            <button className="buttonbg  rounded p-2">
              <Link to="/login" className="text-white p-2"> Login</Link>
            </button>



          )}


        </div>

      </div>
    </>
  );
}

export default Header;
