import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { UserContext } from './Api/context/AppContext';


const CardSummary = ({ totalCartPrice, data, handleSubmit, }) => {

  const { HandleFormShow, showForm } = useContext(UserContext)
  const queryClient = useQueryClient();

  const handleCheckoutClick = () => {
    const productsInfo = data.map((item) => ({
      user: item.user.id,
      qty: item.qty,
      part_number: item.part_number.id,
    }));


    handleSubmit(totalCartPrice, productsInfo);
  };

  return (
    <>
      {/* <form className="mb-5" action="">
        <div className="input-group">
          <input type="text" className="form-control p-4" placeholder="Coupon Code" />
          <div className="input-group-append">
            <button className="btn btn-primary">Apply Coupon</button>
          </div>
        </div>
      </form> */}
      <div className="card  mb-5">
        <div className="card-header   text-center footerbg">
          <h4 className="font-weight-semi-bold  m-0 text-white">Cart Summary</h4>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-between mb-3 pt-1">
            <h5 className="font-weight-medium mx-3 text-dark">Sub total</h5>
            <h5 className="font-weight-medium mx-3">
              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalCartPrice)}
            </h5>
          </div>
          <div className="d-flex justify-content-between">{/* Additional information goes here */}</div>
        </div>
        <div className="card-footer border-secondary bg-transparent">
          <div className="d-flex justify-content-between mt-2">
            <h5 className="font-weight-bold text-success">Total</h5>
            <h5 className="font-weight-bold text-success">
              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalCartPrice)}
            </h5>
          </div>


          {
            showForm ? <button className="btn btn-block buttonbg my-3 py-3 text-white font-weight-semi-bold btn-bounce-on-hover" onClick={handleCheckoutClick}>
              <i class="fas fa-rupee-sign fa-lg"></i> Pay Now
            </button>
              :
              <button className="btn btn-block buttonbg my-3 py-3 text-white font-weight-semi-bold btn-bounce-on-hover rounded" onClick={HandleFormShow}>
              <i className="fas fa-bolt"></i> Proceed To Checkout
            </button>
            
            
            
          }








        </div>
      </div>
    </>
  );
};

export default CardSummary;
