import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom'
import axios from "axios";
import { UserContext } from "./Api/context/AppContext";
import logofoot from "./assets/logowikitek.png"


const Footer = () => {

    const { user, cardCount, handleLogout } = useContext(UserContext);

    const [logo, setLogo] = useState("img/logo-removebg-preview.png");



    useEffect(() => {
        const userNameAmit = localStorage.getItem("emailData");
        const UserPassword = localStorage.getItem("PasswordAmit");

        // console.log("user", userNameAmit);
        // console.log("password", UserPassword);

        if (user) {
            axios
                .post("http://143.244.142.0/api/v1/accounts/login", {
                    username: userNameAmit,
                    password: UserPassword,
                })
                .then((response) => {
                    const { data } = response;
                    if (data && data.success) {
                        const organization = data.data.org;
                        if (organization && organization.logo) {
                            // setLogo(`http://143.244.142.0${organization.logo}`); // Set the logo URL dynamically after successful login
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error fetching login data:", error);
                });
        }


    }, [user]);

    return (
        <>
            {/* <!-- Footer Start --> */}
            <div class="container-fluid footer-container  footerbg text-warning mt-3 pt-5">

                

                <div class="row px-xl-5 ">
                    <div class="col-lg-4 col-md-12  pr-3 pr-xl-5">
                        <Link to="/home" class="text-decoration-none">
                            {<img className="logo_head" src={logofoot} alt="No Img" />}
                        </Link>
                        <p className='pt-2 mt-2 text-dark'>wikitek1 is an online marketplace which is dedicated to the automotive aftermarket. we cater to workshops and individual consumers of multiple vehicle segments for their requirements on the spare parts, accessories, tools & equipment and consumables needs.</p>

                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="row d-flex justify-content-around">
                            
                            <div class="col-md-4 mb-5">
                                <h5 class="font-weight-bold text-white mb-4 "> Customer support</h5>
                                <div class="d-flex flex-column justify-content-start ">
                                    <Link class="text-dark mb-2" to="/home/faq"><i class="fa fa-angle-right mr-2"></i>FAQs</Link>
                                    <Link class="text-dark mb-2" to="/home/help"><i class="fa fa-angle-right mr-2"></i>privacy policy</Link>
                                    {/* <Link class="text-dark mb-2" to="/home/contact"><i class="fa fa-angle-right mr-2"></i>Contact us </Link> */}


                                </div>
                            </div>
                            <div class="col-md-4 mb-5">
                                <h5 class="font-weight-bold text-white mb-4">Contact</h5>
                                <div>
                                    <p className="mb-2 text-dark"><i class="fa fa-map-marker-alt text-dark mr-3"></i>307,konark plaza ,wagholi,pune</p>
                                    <p className="mb-2 text-dark"><i class="fa fa-envelope text-dark mr-3"></i>support@wikitek.in</p>
                                    <p className="mb-0 text-dark"><i class="fa fa-phone-alt text-dark mr-3"></i>+91 9028347071</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row border-top border-light mx-xl-5 py-4">
                    <div class="col-md-6 px-xl-0">
                        <p class="mb-md-0 text-center text-md-left text-white">
                            &copy; <a class="text-white font-weight-semi-bold" href="http://143.110.190.226/">Inventab MarketPlace</a>. All Rights Reserved  <br />
                            
                            Distributed By <a href="www.wikitek.in" target="#" className="text-dark">Inventab MarketPlace</a>
                        </p>
                    </div>
                    <div class="col-md-6 px-xl-0 text-center text-md-right">
                        <img class="img-fluid" src="img/payments.png" alt="" />
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}
        </>
    )
}

export default Footer