import React, { useContext } from 'react';
import "./history.css";
import OrderSingledata from './orders/OrderSingledata';
import Loader from './Loader';




const History = ({ data, error, isLoading, SingleorderData }) => {


    const formatDate = (dateTimeString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short',
        };

        const formattedDate = new Date(dateTimeString).toLocaleString('en-US', options);
        return formattedDate;
    };

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
        
            <div className='container'>
                <div className='row d-flex justify-content-end'>
                    <div className='col-md-6'>
                        <h1>profile</h1>
                    </div>
                    <div className='col-md-6 mx-auto'>
                        <div>

                            {data?.map((item) => (
                                <div className='order-com mb-3 bg-dark' key={item.order_id}>
                                    <div className='main_order'>
                                        <h5 className='text-info' onClick={() => SingleorderData(item.id)}>{item.order_id}</h5>
                                        
                                        <button className="btn btn-warning btn-plus rounded">
                                            Track Order
                                        </button>
                                    </div>
                                    <table className="table mt-2">
                                        <tbody className="table-group-divider">
                                            <tr>
                                                <td>
                                                    <span className='title-span'>Date</span><br />
                                                    <span className='text-white'>{formatDate(item.created)}</span>
                                                </td>
                                                {item.order_payment.length > 0 && (
                                                    <>
                                                        <td className='text-white'>
                                                            <span>Payment Id :</span><br />
                                                            <span>{item.order_payment[0].payment_id}</span>
                                                        </td>
                                                        <td className='text-white'>
                                                            <span>Amount</span><br />
                                                            <span>{item.order_payment[0].amount}</span>
                                                        </td>
                                                        <td className='text-white'>
                                                            <span>Status</span><br />
                                                            <span className={`text-${item.order_payment[0].status === "Success" ? 'success' : 'danger'}`}>
                                                                {item.order_payment[0].status}
                                                            </span>
                                                        </td>

                                                    </>
                                                )}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default History;
