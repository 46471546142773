import React, { useEffect, useState } from 'react'
import { UserContext } from "./Api/context/AppContext";
import CategoLink from './categories/CategoLink';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useContext } from 'react';
function Categorieslist() {


  const fetchData = async () => {
    const storedMarket = localStorage.getItem('make');
    try {
      // const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?market_place=enata-automotive`);
      const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?market_place=wikitek1`);

      return response.data.results;
    } catch (error) {
      throw error;
    }
  };



  const { data, isLoading, isError, error } = useQuery(['menu-data'], fetchData);






  return (
    <div className="col-md-12 d-none d-lg-block">
     
      <h5 className="text-center header-container text-white footerbg py-3 rounded">Categories</h5>
     

      <CategoLink data={data} />
    </div>
  )
}

export default Categorieslist