

import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const AddAddressModal = ({ show, handleClose, formData, handleNewAddressAdded }) => {
  const [org, setOrgName] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [gst_no, setGstNo] = useState('');
  const [address_types, setAddressTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAddressType, setSelectedAddressType] = useState('');
  const [OrgOrg, setOrg] = useState('');

  const loginResponse = JSON.parse(localStorage.getItem('loginResponse'));
  const companyName = loginResponse?.data?.org?.company_name;
  const companyId = loginResponse?.data?.org?.id;
  // console.log(formData,"modalpage")
  useEffect(() => {
    async function fetchAddressTypes() {
      try {
        const response = await axios.get('http://143.244.142.0/api/v1/organizations/fetch/address/type');
        if (response.status === 200) {
          setAddressTypes(response.data.results || []);
        } else {
          throw new Error('Failed to fetch address types');
        }
      } catch (error) {
        console.error(error);
        setError('Failed to fetch address types');
      } finally {
        setLoading(false);
      }
    }

    fetchAddressTypes();
  }, []);

  const handleSelectChange = (e) => {
    setSelectedAddressType(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      
      const response = await axios.post(
        'http://143.244.142.0/api/v1/organizations/new/org/address/',
        {
          org: companyId,
          address_type: selectedAddressType,
          pincode,
          gst_no,
          gst_cert: null,
          address,
          country: null,
        },
        
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
       
      );

      const newAddress = {
        org: companyId,
          address_type: selectedAddressType,
          pincode,
          gst_no,
          gst_cert: null,
          address,
          country: null,
        // Include other details as needed
      };
      handleNewAddressAdded(newAddress);

      if (response.status === 200) {
        handleClose();
      } else {
        throw new Error('Failed to add address');
      }
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show error message)
    }
  };

  

  return (
    <>
      <Modal show={show} onHide={handleClose} centered >
        <Modal.Header closeButton className='footerbg'>
          <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <input type='text' placeholder='Organization Name' value={formData.billing_company_name} className='m-2 w-75'/>
            <div>
            <label htmlFor='addressType' className='m-2 '>Address Type:</label>
            {Array.isArray(address_types) && address_types.length > 0 ? (
              <select id='addressType' value={selectedAddressType} onChange={handleSelectChange} className='m-2 w-75'>
                <option value=''>Select Address Type</option>
                {address_types.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            ) : (
              <p>No address types available</p>
            )}
            </div>
            

            <input
              className='m-2 w-75'
              type='number'
              placeholder='Pincode'
              value={pincode }
              onChange={(e) => setPincode(e.target.value)}
            />
            <input
            className='m-2 w-75'
              type='text'
              placeholder='Address'
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <input
            className='m-2 w-75'
              type='text'
              placeholder='GST Number'
              value={gst_no}
              onChange={(e) => setGstNo(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='buttonbg text-white' onClick={handleClose}>
            Close
          </Button>
          <Button className='footerbg' onClick={handleSubmit}>
            Add Address
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddAddressModal;



// import React, { useState, useEffect } from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import axios from 'axios';

// const AddAddressModal = ({ show, handleClose, formData ,handleNewAddressAdded}) => {
//   const [address, setAddress] = useState('');
//   const [pincode, setPincode] = useState('');
//   const [gst_no, setGstNo] = useState('');
//   const [address_types, setAddressTypes] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedAddressType, setSelectedAddressType] = useState('');
//   const [companyName, setCompanyName] = useState('');

//   const loginResponse = JSON.parse(localStorage.getItem('loginResponse'));
//   const companyId = loginResponse?.data?.org?.id;
//   console.log(formData,"bhushanformdatatnew")
//   useEffect(() => {
//     async function fetchAddressTypes() {
//       try {
//         const response = await axios.get('http://143.244.142.0/api/v1/organizations/fetch/address/type');
//         if (response.status === 200) {
//           setAddressTypes(response.data.results || []);
//           setCompanyName(loginResponse?.data?.org?.company_name || '');
//         } else {
//           throw new Error('Failed to fetch address types');
//         }
//       } catch (error) {
//         console.error(error);
//         setError('Failed to fetch address types');
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchAddressTypes();
//   }, [loginResponse]);

//   const handleSelectChange = (e) => {
//     setSelectedAddressType(e.target.value);
//   };

//   const handleSubmit = async () => {
//     try {
//       const response = await axios.post(
//         'http://143.244.142.0/api/v1/organizations/new/org/address/',
//         {
//           org: companyId,
//           address_type: selectedAddressType,
//           pincode,
//           gst_no,
//           gst_cert: null,
//           address,
//         },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       if (response.status === 200) {
//         handleClose();
//       } else {
//         throw new Error('Failed to add address');
//       }
//     } catch (error) {
//       console.error(error);
//       // Handle error (e.g., show error message)
//     }

//     const newAddress = { /* extract new address details from form */ };
//     handleNewAddressAdded(newAddress);
//   };

//   return (
//     <>
//       <Modal show={show} onHide={handleClose} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Add New Address</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className='row'>
//             <input type='text' placeholder='Organization Name' value={formData.billing_company_name} readOnly />
//             <label htmlFor='addressType'>Address Type:</label>
//             {Array.isArray(address_types) && address_types.length > 0 ? (
//               <select id='addressType' value={selectedAddressType} onChange={handleSelectChange}>
//                 <option value=''>Select Address Type</option>
//                 {address_types.map((type) => (
//                   <option key={type.id} value={type.id}>
//                     {type.name}
//                   </option>
//                 ))}
//               </select>
//             ) : (
//               <p>No address types available</p>
//             )}
//             <input
//               type='number'
//               placeholder='Pincode'
//               value={pincode}
//               onChange={(e) => setPincode(e.target.value)}
//             />
//             <input
//               type='text'
//               placeholder='Address'
//               value={address}
//               onChange={(e) => setAddress(e.target.value)}
//             />
//             <input
//               type='text'
//               placeholder='GST Number'
//               value={gst_no}
//               onChange={(e) => setGstNo(e.target.value)}
//             />
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button className='buttonbg text-white' onClick={handleClose}>
//             Close
//           </Button>
//           <Button className='footerbg' onClick={handleSubmit}>
//             Add Address
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default AddAddressModal;
