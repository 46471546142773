import React from 'react'
import Footer from './Footer'
import Chatbot from './Chatbot'
import { Link } from 'react-router-dom'
import { useState } from 'react';

const Contact = () => {

    const [showChat, setShowChat] = useState(false);

    const handleChatToggle = () => {
        setShowChat(!showChat);
    };

    return (

        <>

            {/* <!-- Page Header Start --> */}
            {/* <div class="container-fluid bg-secondary mb-5">
                <div class="d-flex flex-column align-items-center justify-content-center" style={{ "min-height": "300px" }}>
                    <h1 class="font-weight-semi-bold text-uppercase mb-3">Contact Us</h1>
                    <div class="d-inline-flex">
                        <p class="m-0"><a href="">Home</a></p>
                        <p class="m-0 px-2">-</p>
                        <p class="m-0">Contact</p>
                    </div>
                </div>
            </div> */}
            {/* <!-- Page Header End --> */}


            {/* <!-- Contact Start --> */}
            {/* <div class="container-fluid pt-5">
                <div class="text-center mb-4">
                    <h2 class="section-title px-5"><span class="px-2">Contact For Any Queries</span></h2>
                </div>
                <div class="row px-xl-5">
                    <div class="col-lg-7 mb-5">
                        <div class="contact-form">
                            <div id="success"></div>
                            <form name="sentMessage" id="contactForm" novalidate="novalidate">
                                <div class="control-group">
                                    <input type="text" class="form-control" id="name" placeholder="Your Name"
                                        required="required" data-validation-required-message="Please enter your name" />
                                    <p class="help-block text-danger"></p>
                                </div>
                                <div class="control-group">
                                    <input type="email" class="form-control" id="email" placeholder="Your Email"
                                        required="required" data-validation-required-message="Please enter your email" />
                                    <p class="help-block text-danger"></p>
                                </div>
                                <div class="control-group">
                                    <input type="text" class="form-control" id="subject" placeholder="Subject"
                                        required="required" data-validation-required-message="Please enter a subject" />
                                    <p class="help-block text-danger"></p>
                                </div>
                                <div class="control-group">
                                    <textarea class="form-control" rows="6" id="message" placeholder="Message"
                                        required="required"
                                        data-validation-required-message="Please enter your message"></textarea>
                                    <p class="help-block text-danger"></p>
                                </div>
                                <div>
                                    <button class="btn btn-primary py-2 px-4" type="submit" id="sendMessageButton">Send
                                        Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-5 mb-5">
                        <h5 class="font-weight-semi-bold mb-3">Get In Touch</h5>
                        <p>Justo sed diam ut sed amet duo amet lorem amet stet sea ipsum, sed duo amet et. Est elitr dolor elitr erat sit sit. Dolor diam et erat clita ipsum justo sed.</p>
                        <div class="d-flex flex-column mb-3">
                            <h5 class="font-weight-semi-bold mb-3">Store 1</h5>
                            <p class="mb-2"><i class="fa fa-map-marker-alt text-primary mr-3"></i>123 Street, New York, USA</p>
                            <p class="mb-2"><i class="fa fa-envelope text-primary mr-3"></i>info@example.com</p>
                            <p class="mb-2"><i class="fa fa-phone-alt text-primary mr-3"></i>+012 345 67890</p>
                        </div>
                        <div class="d-flex flex-column">
                            <h5 class="font-weight-semi-bold mb-3">Store 2</h5>
                            <p class="mb-2"><i class="fa fa-map-marker-alt text-primary mr-3"></i>123 Street, New York, USA</p>
                            <p class="mb-2"><i class="fa fa-envelope text-primary mr-3"></i>info@example.com</p>
                            <p class="mb-0"><i class="fa fa-phone-alt text-primary mr-3"></i>+012 345 67890</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- Contact End --> */}


            <div className='container-fluid '>
                <div className='text-center container'>
                    <h1 >Contact us</h1>
                    <hr className='w-25'></hr>
                    <div></div>
                    <h5 className=' text-muted '>
                        Lets get in touch with each other let us know how we can increase our customer service experiance
                        your feed back is most important for us !
                    </h5>
                </div>

                <div className='container  d-flex align-items-center' style={{ height: "500px" }}>

                    <div className='row  d-flex justify-content-around'>
                        <div className='col-md-5' style={{
                            boxShadow: '0 4px 8px 0 rgba(251,147,0,1)', // Blue shadow
                            animation: 'glow 2s infinite', // Glowing effect
                        }}>

                            {/* <div class="card mb-3" style={{ maxWidth: "540px" }}>
                                <div class="row no-gutters">
                                    <div class="col-md-6">
                                        <img src="..." class="card-img" alt="..." />
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="card p-3" >
                                <img src="https://assets-global.website-files.com/59b2e810343914000142586f/59e642762c988e00010a70c0_bluecart-contact-sales-contact-icon.svg" width="88" alt="Sales Briefcase Icon" />
                                <h4 class="n-feature-card-header">Sales</h4><p className="n-paragraph-small n-no-padding-top cardstyle">Whether you're looking for fast response times or advanced product knowledge,our sales team provides multiple services to fit your needs. </p><p class="n-paragraph-small n-no-padding-top"> <strong>Sales team:</strong>   7058866609‬ </p>



                            </div>
                        </div>


                        <div className='col-md-5' style={{
                            boxShadow: '0 4px 8px 0 rgba(245,245,245)', // Blue shadow
                            animation: 'glow 2s infinite', // Glowing effect
                        }}>

                            {/* <div class="card mb-3 h-75" style={{ maxWidth: "540px" }}>
                                <div class="row no-gutters">
                                    <div class="col-md-6">
                                        <img src="..." class="card-img" alt="..." />
                                    </div>
                                    <div class="col-md-8">
                                        <div class="card-body">
                                            <h5 class="card-title">Card title</h5>
                                            <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div class="card p-3">
                                <img src="https://assets-global.website-files.com/59b2e810343914000142586f/59e642763d77d6000110b9f2_bluecart-help-support-contact-icon.svg" width="88" alt="Sales Briefcase Icon" />
                                <h4 class="n-feature-card-header">Help & support</h4><p className="n-paragraph-small n-no-padding-top cardstyle">If you're having trouble accessing a wikitek product, there's a chance we're currently experiencing a temporary problem.<br></br> </p><p class="n-paragraph-small n-no-padding-top"> <strong>Contact:</strong>  9028347071‬ </p>


                            </div>


                        </div>

                    </div>

                </div>

                {/* <div className='row '>
                    <div className='col-md-12 mb-3'>
                        <div className='col-md-5'>
                            {showChat && <Chatbot />}
                        </div>

                    </div>

                    <div className='col-md-5 '>
                        <button className="p-2 footerbg rounded text-white " onClick={handleChatToggle}>chat with us
                            <i className=" ml-2 fas fa-headset"></i>
                        </button>
                    </div>

                </div> */}

            </div>
            <Footer />
        </>

    )
}

export default Contact