// import React, { useState } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import axios from 'axios';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { AiOutlineAppstore, AiOutlineMenu, AiFillDelete } from 'react-icons/ai';

// import CartItem from './product/CartItem';
// import Footer from './Footer';
// import Loader from './Loader';
// import { useQuery } from 'react-query';

// const Testshop = () => {
//     const { partCategoryName, subCategoryName, subCategoryId } = useParams();

//     const decodedPartCategoryName = decodeURI(partCategoryName);
//     const decodedSubCategoryName = decodeURI(subCategoryName);

//     const [priceFilter, setPriceFilter] = useState('all');
//     const [selectedCategories, setSelectedCategories] = useState([]);
//     const [isGridView, setIsGridView] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredProducts, setFilteredProducts] = useState([]);


//     const fetchData = async () => {
//         try {
//             const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?part_category=${subCategoryId}`);
//             return response.data.results;
//         } catch (error) {
//             throw error;
//         }
//     };

//     const { data, isLoading, isError, error } = useQuery(['categories-data', subCategoryId], fetchData);

//     const subcat = [...new Set((data?.map((product) => product.sub_category.name) || []))];

//     const handlePriceFilterChange = (priceRange) => {
//         setPriceFilter(priceRange);
//     };

//     const handleCategoryFilterChange = (category) => {
//         if (selectedCategories.includes(category)) {
//             setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
//         } else {
//             setSelectedCategories([...selectedCategories, category]);
//         }
//     };

//     const filterProductsByPriceAndCategory = (products, priceFilter, categoryFilter) => {
//         return (products || []).filter((product) => {

//             const price = product.mrp;
//             const newprice = 0;



//             const category = product.sub_category.name;

//             if (priceFilter !== 'all' && categoryFilter.length > 0) {
//                 return priceFilterCheck(price, priceFilter) && categoryFilter.includes(category);
//             } else if (priceFilter !== 'all') {
//                 return priceFilterCheck(price, priceFilter);
//             } else if (categoryFilter.length > 0) {
//                 return categoryFilter.includes(category);
//             } else {
//                 return true;
//             }
//         });
//     };

//     const priceFilterCheck = (price, priceFilter) => {
//         switch (priceFilter) {
//             case 'price-1':
//                 return price >= 0 && price <= 1000;
//             case 'price-2':
//                 return price > 1000 && price <= 2000;
//             case 'price-3':
//                 return price > 2000 && price <= 3000;
//             case 'price-4':
//                 return price > 3000 && price <= 4000;
//             case 'price-5':
//                 return price > 4000;
//             default:
//                 return false;
//         }
//     };

//     const handleDeleteSelectedCategory = (category) => {
//         setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
//     };

//     const showGridView = () => {
//         setIsGridView(true);
//     };

//     const showListView = () => {
//         setIsGridView(false);
//     };


//     const handleInputChange = (event) => {
//         const searchTerm = event.target.value;
//         setSearchTerm(searchTerm);

//         const filtered = filterProductsBySearch(data, searchTerm);


//         setFilteredProducts(filtered);
//     };

//     const filterProductsBySearch = (products, searchTerm) => {
//         return (products || []).filter((product) => {
//             const shortDescription = (product.short_description || '').toLowerCase();
//             return shortDescription.includes(searchTerm.toLowerCase());
//         });
//     };

//     // const filteredProducts = filterProductsByPriceAndCategory(data, priceFilter, selectedCategories);
//     const productsToDisplay = searchTerm ? filteredProducts : filterProductsByPriceAndCategory(data, priceFilter, selectedCategories);

//     return (
//         <>


//             <div className="container-fluid  mb-2">
//                 <div className=" text-center ">
//                     <h2 className="font-weight-semi-bold text-dark text-uppercase mb-3">Product Catalog</h2>
//                     <hr className='w-25'></hr>
//                     {/* <div className="d-inline-flex">
//                         <p className="m-0"><Link to="/home">Home</Link></p>
//                         <p className="m-0 px-2">-</p>
//                         <p className="m-0">Product Catalog</p>
//                     </div> */}

//                     <div className='top_route'>
//                         {partCategoryName && subCategoryName && (
//                             <>
//                                 <Link to={`/home`} className="btn_top">
//                                     Home /{decodedPartCategoryName}/
//                                 </Link>
//                                 {decodedSubCategoryName}
//                             </>
//                         )}
//                     </div>
//                 </div>
//             </div>

//             <div className="container-fluid  shop_prod">
//                 <div className="row px-xl-5">
//                     <div className="col-lg-3 col-md-12">
//                         {data?.length > 0 && (
//                             <div className="border-bottom mb-4 pb-4">
//                                 <h5 className="font-weight-semi-bold mb-4">Filter by Price</h5>
//                                 <form>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'all'}
//                                             onChange={() => handlePriceFilterChange('all')}
//                                             id="price-all"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-all">
//                                             All Prices
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-1'}
//                                             onChange={() => handlePriceFilterChange('price-1')}
//                                             id="price-1"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-1">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(0)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(1000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-2'}
//                                             onChange={() => handlePriceFilterChange('price-2')}
//                                             id="price-2"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-2">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(1000)}- {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(2000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-3'}
//                                             onChange={() => handlePriceFilterChange('price-3')}
//                                             id="price-3"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-3">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(2000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(3000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-4'}
//                                             onChange={() => handlePriceFilterChange('price-4')}
//                                             id="price-4"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-4">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(3000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(4000)}
//                                         </label>
//                                     </div>
//                                     <div className="custom-control custom-radio d-flex align-items-center justify-content-between">
//                                         <input
//                                             type="radio"
//                                             className="custom-control-input"
//                                             checked={priceFilter === 'price-5'}
//                                             onChange={() => handlePriceFilterChange('price-5')}
//                                             id="price-5"
//                                         />
//                                         <label className="custom-control-label" htmlFor="price-5">
//                                             {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(4000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(5000)}
//                                         </label>
//                                     </div>
//                                 </form>
//                             </div>
//                         )}

//                         {data?.length > 0 && (
//                             <div className="border-bottom mb-4 pb-4 d-none   ">
//                                 <h5 className="font-weight-semi-bold mb-4">Filter by Categories</h5>
//                                 <form>
//                                     {subcat.map((category) => (
//                                         <div
//                                             key={category}
//                                             className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3"
//                                         >
//                                             <input
//                                                 type="checkbox"
//                                                 className="custom-control-input"
//                                                 checked={selectedCategories.includes(category)}
//                                                 onChange={() => handleCategoryFilterChange(category)}
//                                                 id={`category-${category}`}
//                                             />
//                                             <label
//                                                 className="custom-control-label"
//                                                 htmlFor={`category-${category}`}
//                                             >
//                                                 {category}
//                                             </label>
//                                         </div>
//                                     ))}
//                                 </form>
//                             </div>
//                         )}
//                     </div>

//                     <div className="col-lg-9 col-md-12">
//                         <div className=" d-flex flex-wrap">
//                             {selectedCategories.length > 0 && (
//                                 selectedCategories.map((item, index) => (
//                                     <div key={index} className="d-flex align-items-center mb-2">
//                                         <button
//                                             className="btn btn-primary mr-2"
//                                             onClick={() => handleDeleteSelectedCategory(item)}
//                                         >
//                                             {item} <AiFillDelete />
//                                         </button>
//                                     </div>
//                                 ))
//                             )}
//                         </div>

//                         <div className="row pb-3">
//                             <div className="col-12 pb-1 p-3">
//                                 <div className="d-flex align-items-center justify-content-around mb-4">
//                                     <form action="">
//                                         <div className="input-group">
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 placeholder="Search by name"
//                                                 value={searchTerm}
//                                                 onInput={handleInputChange}
//                                             />
//                                             <div className="input-group-append ml-3">
//                                                 <span className="input-group-text bg-dark">
//                                                     <i className="fa fa-search text-warning flip-on-hover"></i>
//                                                 </span>

//                                                 <span
//                                                     className="input-group-text bg-dark text-warning ml-3"
//                                                     onClick={showGridView}
//                                                 >
//                                                     <AiOutlineAppstore />
//                                                 </span>
//                                                 <span
//                                                     className="input-group-text bg-dark text-warning ml-3"
//                                                     onClick={showListView}
//                                                 >
//                                                     <AiOutlineMenu />
//                                                 </span>
//                                             </div>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>

//                             {isLoading ? (
//                                 <Loader />
//                             ) : (productsToDisplay|| []).length > 0 ? (
//                                 <InfiniteScroll
//                                     dataLength={(productsToDisplay || []).length}
//                                     // hasMore={(filteredProducts || []).length < 500}
//                                     // height={750}
//                                 >
//                                     <div className={`d-flex ${isGridView ? 'flex-wrap' : 'flex-column'}`}>
//                                         {(productsToDisplay || []).map((product, index) => (
//                                             <CartItem product={product} key={index} isGridView={isGridView} />
//                                         ))}
//                                     </div>
//                                 </InfiniteScroll>
//                             ) : (
//                                 <p className="text-center text-dark">
//                                     No products match the selected price range or category.
//                                 </p>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <Footer />
//         </>
//     );
// };

// export default Testshop;


import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AiOutlineAppstore, AiOutlineMenu, AiFillDelete } from 'react-icons/ai';

import CartItem from './product/CartItem';
import Footer from './Footer';
import Loader from './Loader';
import { useQuery } from 'react-query';

const Testshop = () => {
    const { partCategoryName, subCategoryName, subCategoryId } = useParams();

    const decodedPartCategoryName = decodeURI(partCategoryName);
    const decodedSubCategoryName = decodeURI(subCategoryName);

    const [priceFilter, setPriceFilter] = useState('all');
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isGridView, setIsGridView] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);

    
    const fetchData = async () => {
        console.log('in fetch data')
        try {

            const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?sub_category=${subCategoryId}`);
            // const response = await axios.get(`http://143.244.142.0/api/v1/parts/fetch/parts/?market_place=wikitek1`);

            console.log(response.data.results, "api response")
            return response.data.results;
        } catch (error) {
            throw error;
        }
    };

    const { data, isLoading, isError, error } = useQuery(['categories-data', subCategoryId], fetchData);

    const subcat = [...new Set((data?.map((product) => product.sub_category.name) || []))];

    const handlePriceFilterChange = (priceRange) => {
        setPriceFilter(priceRange);
    };

    const handleCategoryFilterChange = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const filterProductsByPriceAndCategory = (products, priceFilter, categoryFilter) => {
        return (products || []).filter((product) => {
            const price = product.mrp;
            const category = product.sub_category.name;
            const marketplace = (product.market_place[0]?.marketplace_name || '').toLowerCase(); // Extract the marketplace_name, if available

            if (
                priceFilter !== 'all' &&
                categoryFilter.length > 0 &&
                marketplace === 'wikitek1' // Check for marketplace_name 'wikitek1'
            ) {
                return (
                    priceFilterCheck(price, priceFilter) &&
                    categoryFilter.includes(category) &&
                    marketplace === 'wikitek1'
                );
            } else if (priceFilter !== 'all') {
                return priceFilterCheck(price, priceFilter) && marketplace === 'wikitek1';
            } else if (categoryFilter.length > 0) {
                return categoryFilter.includes(category) && marketplace === 'wikitek1';
            } else {
                return marketplace === 'wikitek1';
            }
        });
    };

    const priceFilterCheck = (price, priceFilter) => {
        switch (priceFilter) {
            case 'price-1':
                return price >= 0 && price <= 1000;
            case 'price-2':
                return price > 1000 && price <= 2000;
            case 'price-3':
                return price > 2000 && price <= 3000;
            case 'price-4':
                return price > 3000 && price <= 4000;
            case 'price-5':
                return price > 4000;
            default:
                return false;
        }
    };

    const handleDeleteSelectedCategory = (category) => {
        setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
    };

    const showGridView = () => {
        setIsGridView(true);
    };

    const showListView = () => {
        setIsGridView(false);
    };


    const handleInputChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        const filtered = filterProductsBySearch(data, searchTerm);


        setFilteredProducts(filtered);
    };

    const filterProductsBySearch = (products, searchTerm) => {
        return (products || []).filter((product) => {
            const shortDescription = (product.short_description || '').toLowerCase();
            return shortDescription.includes(searchTerm.toLowerCase());
        });
    };

    // const filteredProducts = filterProductsByPriceAndCategory(data, priceFilter, selectedCategories);
    const productsToDisplay = searchTerm ? filteredProducts : filterProductsByPriceAndCategory(data, priceFilter, selectedCategories);

    return (
        <>


            <div className="container-fluid  mb-2">
                <div className=" text-center ">
                    <h2 className="font-weight-semi-bold text-dark text-uppercase mb-3">Product Catalog</h2>
                    <hr className='w-25'></hr>
                    {/* <div className="d-inline-flex">
                        <p className="m-0"><Link to="/home">Home</Link></p>
                        <p className="m-0 px-2">-</p>
                        <p className="m-0">Product Catalog</p>
                    </div> */}

                    <div className='top_route'>
                        {partCategoryName && subCategoryName && (
                            <>
                                <Link to={`/home`} className="btn_top">
                                    Home /{decodedPartCategoryName}/
                                </Link>
                                {decodedSubCategoryName}
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="container-fluid  shop_prod">
                <div className="row px-xl-5">
                    <div className="col-lg-3 col-md-12">
                        {data?.length > 0 && (
                            <div className="border-bottom mb-4 pb-4">
                                <h5 className="font-weight-semi-bold mb-4">Filter by Price</h5>
                                <form>
                                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            checked={priceFilter === 'all'}
                                            onChange={() => handlePriceFilterChange('all')}
                                            id="price-all"
                                        />
                                        <label className="custom-control-label" htmlFor="price-all">
                                            All Prices
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            checked={priceFilter === 'price-1'}
                                            onChange={() => handlePriceFilterChange('price-1')}
                                            id="price-1"
                                        />
                                        <label className="custom-control-label" htmlFor="price-1">
                                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(0)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(1000)}
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            checked={priceFilter === 'price-2'}
                                            onChange={() => handlePriceFilterChange('price-2')}
                                            id="price-2"
                                        />
                                        <label className="custom-control-label" htmlFor="price-2">
                                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(1000)}- {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(2000)}
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            checked={priceFilter === 'price-3'}
                                            onChange={() => handlePriceFilterChange('price-3')}
                                            id="price-3"
                                        />
                                        <label className="custom-control-label" htmlFor="price-3">
                                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(2000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(3000)}
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between mb-3">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            checked={priceFilter === 'price-4'}
                                            onChange={() => handlePriceFilterChange('price-4')}
                                            id="price-4"
                                        />
                                        <label className="custom-control-label" htmlFor="price-4">
                                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(3000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(4000)}
                                        </label>
                                    </div>
                                    <div className="custom-control custom-radio d-flex align-items-center justify-content-between">
                                        <input
                                            type="radio"
                                            className="custom-control-input"
                                            checked={priceFilter === 'price-5'}
                                            onChange={() => handlePriceFilterChange('price-5')}
                                            id="price-5"
                                        />
                                        <label className="custom-control-label" htmlFor="price-5">
                                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(4000)} - {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(5000)}
                                        </label>
                                    </div>
                                </form>
                            </div>
                        )}

                        {data?.length > 0 && (
                            <div className="border-bottom mb-4 pb-4 d-none   ">
                                <h5 className="font-weight-semi-bold mb-4">Filter by Categories</h5>
                                <form>
                                    {subcat.map((category) => (
                                        <div
                                            key={category}
                                            className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3"
                                        >
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                checked={selectedCategories.includes(category)}
                                                onChange={() => handleCategoryFilterChange(category)}
                                                id={`category-${category}`}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={`category-${category}`}
                                            >
                                                {category}
                                            </label>
                                        </div>
                                    ))}
                                </form>
                            </div>
                        )}
                    </div>

                    <div className="col-lg-9 col-md-12">
                        <div className=" d-flex flex-wrap">
                            {selectedCategories.length > 0 && (
                                selectedCategories.map((item, index) => (
                                    <div key={index} className="d-flex align-items-center mb-2">
                                        <button
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleDeleteSelectedCategory(item)}
                                        >
                                            {item} <AiFillDelete />
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>

                        <div className="row pb-3">
                            <div className="col-12 pb-1 p-3">
                                <div className="d-flex align-items-center justify-content-around mb-4">
                                    <form action="">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search by name"
                                                value={searchTerm}
                                                onInput={handleInputChange}
                                            />
                                            <div className="input-group-append ml-3">
                                                <span className="input-group-text bg-dark">
                                                    <i className="fa fa-search text-warning flip-on-hover"></i>
                                                </span>

                                                <span
                                                    className="input-group-text bg-dark text-warning ml-3"
                                                    onClick={showGridView}
                                                >
                                                    <AiOutlineAppstore />
                                                </span>
                                                <span
                                                    className="input-group-text bg-dark text-warning ml-3"
                                                    onClick={showListView}
                                                >
                                                    <AiOutlineMenu />
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {isLoading ? (
                                <Loader />
                            ) : (productsToDisplay || []).length > 0 ? (
                                <InfiniteScroll
                                    dataLength={(productsToDisplay || []).length}
                                // hasMore={(filteredProducts || []).length < 500}
                                // height={750}
                                >
                                    <div className={`d-flex ${isGridView ? 'flex-wrap' : 'flex-column'}`}>
                                        {(productsToDisplay || []).map((product, index) => (
                                            <CartItem product={product} key={index} isGridView={isGridView} />
                                        ))}
                                    </div>
                                </InfiniteScroll>
                            ) : (
                               <div>
                                no products to show 
                               </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>



            <Footer />
        </>
    );


};



export default Testshop;

