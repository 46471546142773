import React, { useContext } from 'react'
import {
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import { useLocation } from 'react-router-dom';

import { UserContext } from './Api/context/AppContext';
import ProtectedRoute from './Api/context/ProtectedRoute';
import RegisterPage from './register/Register';
import LoginPage from './login/Login';
import VerifyOtp from './register/VerifyOtp';
import ForgotPassword from './register/Password';
import Header from "../components/Header"
import YourComponent from "../components/Org/Orgdetails"
import Checkout from "../components/Checkout"
import OrderContainer from './orders/OrderContainer';
import TabComponent from './TabComponent';
import SuccessPage from './SuccessPage';
import History from './History';
import Home from './Home';
import Testshop from './Testshop';
import SingleProduct from './SingleProduct';

import Help from './Help';

const LayoutRoute = () => {
    const { token } = useContext(UserContext)

    const HeaderLogic = () => {
        const location = useLocation();

        const hideHeaderOnPaths = ["/register", "/login", "*", "/otp"];

        const shouldShowHeader = !hideHeaderOnPaths.includes(location.pathname);

        return shouldShowHeader ? <Header /> : null;
    };

    return (
        <>
            <HeaderLogic/>

            <Routes>

                {/* <Route path="*" element={<LoginPage />} /> */}
                < Route path="/register" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<Home />} />
                <Route path="/otp" element={<VerifyOtp />} />
                <Route path="/forget-pass" element={<ForgotPassword />} />
                <Route path="/org-address" element={<YourComponent />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/order" element={<OrderContainer />} />
                <Route path="/success-order" element={<SuccessPage />} />
                {/* <Route path='/history' element ={<History/>}/> */}
                <Route
                    path="/home/categories/:partCategoryName/:subCategoryName/:subCategoryId"
                    element={<Testshop />}
                />

                <Route path="/home/singleproduct/:id" element={<SingleProduct />} />
                <Route path="/home/help" element={<Help/>}/>
                <Route path="*" element={<Navigate to="/login" />} />

                <Route path="/home/*" element={token ? <ProtectedRoute /> : <Navigate to="/login" />} />

            </Routes>

        </>
    )
}

export default LayoutRoute