import React, { useEffect, useState, useContext } from "react";

import Owlslider from "./Owlslider";
import Slider from "./Slider";
import "../components/Home.css";
import axios from "axios";

import Footer from "./Footer";
// import ProductCard from "../components/product/ProductCard";

import Categorieslist from "./Categorieslist";

import ProductContainer from "./product/ProductContainer";
import { useFetchApi } from "./Api/uesFatchapi";
import { UserContext } from "./Api/context/AppContext";
import offer1 from "../components/assets/offer-1.png"
import offer2 from "../components/assets/offer-2.png"
import Header from "./Header";
import wiki1 from "../components/assets/wiki1.jpg";
import wiki2 from "../components/assets/wiki2.png";
import wiki3 from "../components/assets/wiki3.jpg";
import wiki4 from "../components/assets/wiki4.jpg";

const Home = () => {


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [features, setFeatures] = useState([]); // State for features
  const [marketBanner, setMarketBanner] = useState([]); // State for marketBanner




  useEffect(() => {
    // Get the user's name from local storage
    const storedUserName = localStorage.getItem('make')
    // Define the URL of the API you want to fetch data from using the 'userName'
    const apiUrl = `http://143.244.142.0/api/v1/pipo/get/marketplace/list/?marketplace_name=wikitek1`
    // Make a GET request using Axios
    axios.get(apiUrl)
      .then((response) => {
        let result = response.data.results
        // console.log(response.data, "---------result sa");

        // Assuming the response contains an array of data
        setData(response.data.results);
        const foundFeatures = result[0].marketplace_hotproducts || [];
        setFeatures(foundFeatures);
        const foundMarketBanner = result[0].marketplace_banner || [];
        setMarketBanner(foundMarketBanner);


        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container-fluid mb-3 mt-3">
        {/* <Categorieslist /> */}
        <Slider marketbenner={marketBanner} />

      </div>



      <div className="text-center mt-3">
        <h2 className="font-weight-semi-bold text-dark text-uppercase mb-3">featured products</h2>
        <hr className='w-25 bg-info glowing-line'></hr>
      </div>


      <div className="home_content mt-3 ">
        <div className="container-fluid">


          <div className="container-fluid mt-3">
            <div className=" row">
              {/* <ProductContainer product={features} /> */}
              <div className="col-md-3">
                <Categorieslist />
              </div>

              <div className="col-md-9 ">
                <ProductContainer product={features} />
              </div>


            </div>
          </div>
        </div>



        <div className="text-center">
          <h3 className="text-dark p-3 ">Our customer services</h3>
          <hr className='w-25'></hr>
        </div>

        {/* <section className="first_sec">
          <div className="container-fluid  pt-5">


            <div className="row px-xl-5 pb-3">
              <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div
                  className="d-flex align-items-center "
                  style={{ padding: "30px" }}
                >
                  <h1 className="fa fa-check text-warning m-0 mr-3"></h1>
                  <h5 className="font-weight-semi-bold m-0">Quality Product</h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div
                  className="d-flex align-items-center "
                  style={{ padding: "30px" }}
                >
                  <h1 className="fa fa-shipping-fast text-warning m-0 mr-2"></h1>
                  <h5 className="font-weight-semi-bold m-0">Free Shipping</h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div
                  className="d-flex align-items-center "
                  style={{ padding: "30px" }}
                >
                  <h1 className="fas fa-exchange-alt text-warning m-0 mr-3"></h1>
                  <h5 className="font-weight-semi-bold m-0">14-Day Return</h5>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 pb-1">
                <div
                  className="d-flex align-items-center "
                  style={{ padding: "30px" }}
                >
                  <h1 className="fa fa-phone-volume text-warning m-0 mr-3"></h1>
                  <h5 className="font-weight-semi-bold m-0">24/7 Support</h5>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <div className="container-fluid ">
          <div className="row mt-5 justify-content-center">
            <div className="col-lg-12 d-flex ">
              <div class="card w-50 ml-3 shadow-lg p-2 mb-5 bg-white rounded">
                <div class="card-body">
                  <h3 class="card-title text-center text-dark"><h1 className="fa fa-phone-volume text-info m-0 mr-3"></h1>27/7 support</h3>
                  <hr className="w-50"></hr>
                  <p class="card-text texxt-center p-2">The marketplace Help Centre page lists out various types of issues that you may have encountered so that there can be quick resolution and you can go back to shopping online. For example, you can get more information regarding order tracking,
                    delivery date changes, help with returns (and refunds), and much more.</p>

                </div>
              </div>

              <div class="card w-50 ml-3 shadow-lg p-2 mb-5 bg-white rounded">
                <div class="card-body">
                  <h3 class="card-title text-center"><h1 className="fa fa-check text-info m-0 mr-3"></h1>Quality products</h3>
                  <hr className="w-50"></hr>
                  <p class="card-text p-2">Each product on the platform goes through 35–40 such quality checks (done manually by our internal quality-check team using product images)
                    before being approved for listing on the marketplace website!</p>

                </div>
              </div>

              <div class="card w-50 ml-3 shadow-lg p-2 mb-5 bg-white rounded ">
                <div class="card-body">
                  <h3 class="card-title text-center"><h1 className="fa fa-shipping-fast text-info m-0 mr-2"></h1>Fast shipping</h3>
                  <hr className="w-50"></hr>
                  <p class="card-text text-center p-2">For items listed as "In Stock", Sellers will mention the delivery time based on your location pincode (usually 2-3 business days,
                    4-5 business days or 4-6 business days in areas where standard courier service is available).</p>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="videodiv container-fluid mt-3 ">
          
          <div className="text-center">
          <h3 className="text-dark p-3 ">know our products</h3>
          <hr className='w-25'></hr>
        </div>
          
          <div className="row mt-3 ">

            <div className="col-md-12" style={{height:"500px"}}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/FpcrFB8ecUk?si=stycI1OBuV7yg-B-&amp;controls=0&amp;start=368" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            {/* <div className="col-md-6 " style={{width:"560", height:"550px"}}>
              <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src={wiki1} class="d-block w-100" alt="first image" />
                  </div>
                  <div class="carousel-item">
                    <img src={wiki2} class="d-block w-100" alt="second image" />
                  </div>
                  <div class="carousel-item">
                    <img src={wiki3} class="d-block w-100" alt="third image" />
                  </div>
                  <div class="carousel-item">
                    <img src={wiki4} class="d-block w-100" alt="fourth image" />
                  </div>
                </div>
                
              </div>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>


    </>
  );
};

export default Home;
