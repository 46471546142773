import React from 'react'
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../Api/context/AppContext';
import user1 from "../../components/assets/no_image.png";
import "../product/productdata/proctuct.css"

const CartItem = ({ product, isGridView }) => {

    const { addToCard, checkId } = useContext(UserContext)

    const { mrp, short_description, prices, part_number, } = product;
    console.log(product, "bhushanimage")
    const imagefilter = product.default?.attachment;

    const { user } = useContext(UserContext);


    //     const imagesimgl = !loading && data && data.length > 0 && data[0].default && data[0].default.attachment
    //     ? data[0].default.attachment
    //     : null;

    // const attachment1 = !loading && data && data.length > 0 && data[0].documents[0] && data[0].documents[0].attachment
    //     ? data[0].documents[0].attachment
    //     : null;

    // const finalImage = attachment1 || imagesimgl;



    let currentprice = prices; // Assuming you already have prices as an array
    // console.log(currentprice,"-------price" )

    let extractedPrice;
    let discount;

    if (currentprice.length === 1) {
        extractedPrice = currentprice[0].price;
        discount = currentprice[0].discount;
    } else {
        // Handle the case where prices array has more or fewer elements
        extractedPrice = null;
        discount = null; // or provide a default value or an error message
    }

    return (
        <>

            <div className={`${isGridView === false ? "col-lg-12 dev_list" : "col-lg-4   cart_contain pb-1"}`}>
                <div className="card product-item  mb-4">
                    <div className={`${isGridView === false ? "d-flex justify-content-around dev_list_alig" : ""}`}>
                        <div className="card-header product-img position-relative overflow-hidden bg-transparent  p-0">
                        {imagefilter ? (
                            <img className="img-contain" src={imagefilter} alt=""/>
                        ) : (
                            <img className="img-contain" src={user1} alt=""/>
                        )}
                            
                        </div>



                        <div>
                            <div className="card-body  text-center p-0 pt-4 pb-3">
                                <h6 className=" mb-3 text-wrap-break-word">{short_description}</h6>
                                <h6 className=" mb-3 text-wrap-break-word">{part_number}</h6>
                                <div className="d-flex justify-content-center">
                                    {/* <h6> {extractedPrice} </h6>  */}
                                    <h6>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(mrp)}</h6>
                                    {/* <h6 className="text-muted ml-2"><del>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(mrp)}</del></h6> */}
                                    <h6>{discount}</h6>
                                </div>
                            </div>


                            {user ? (
                                <div className="card-footer d-flex justify-content-between bg-light border-none">
                                    <button className='btn btn-sm  footerbg p-2 px-2 rounded'>
                                        <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-dark mr-1"></i>View Detail</Link>
                                    </button>



                                    {!checkId.includes(product.id) ? (
                                        <button
                                            onClick={() => addToCard(product, 1)}
                                            className="btn btn-sm text-white buttonbg p-2 px-2 rounded"
                                        >
                                            <i className="fas fa-shopping-cart text-white  mr-1"></i>
                                            Add To Cart
                                        </button>
                                    ) : (
                                        <button className='bg-dark'>
                                            <Link to="/home/cart"

                                                className="btn btn-sm  p-0 text-white"
                                            >
                                                <i className="fas fa-shopping-cart text-white mr-1"></i>
                                                Go To Cart
                                            </Link>
                                        </button>
                                    )}


                                </div>

                            ) : (
                                <div className=" d-flex justify-content-center bg-light mb-2">
                                    <button className='btn btn-sm  footerbg p-2 px-2 rounded'>
                                        <Link to={`/home/singleproduct/${product.part_number}`} className="btn btn-sm text-dark p-0"><i className="fas fa-eye text-dark mr-1"></i>View Detail</Link>
                                    </button>
                                </div>


                            )}


                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CartItem